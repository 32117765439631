.MessageActions {
    padding: 16px 40px 16px 30px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.05);

    .Icon {
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            color: #dc3545
        }
    }

    &.success {
        border-left: solid 5px #28a745;
        background-color: rgba(#28a745, .05);
    }

    &.error {
        border-left: solid 5px #dc3545;
        background-color: rgba(#dc3545, .05);
    }

    &.warning {
        border-left: solid 5px #ffc107;
        background-color: rgba(#ffc107, .05);
    }

    &.info {
        border-left: solid 5px #17a2b8;
        background-color: #fff;
    }

    &.NoBorder {
        border: none;
    }
}
