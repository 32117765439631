.ImageFileTransfer{
    position: relative;
    text-decoration: underline;
    padding: 2px 5px;
    transition: all .3s;
    &:hover{
        background-color: rgba(217, 217, 217, 0.4);
    }
    img.iconDelete{
        position: absolute;
        top: 50%;
        right: 5px;
        z-index: 2;
        transform: translateY(-50%);
    }
}

.PayoutCopyEmail{
    .TooltipContent{
        min-width: 90px;
        text-align: center;
    }
}

.PayoutTranferInput{
    position: relative;
    .PayoutTranferCopy{
        position: absolute;
        top: 40px;
        right: 25px;
        z-index: 2;
        .TooltipContent{
            min-width: 105px;
            text-align: center;
        }
    }
}

.TxtToWorkConfirm{
    text-transform: lowercase;
    margin-top: -15px;
    &:first-letter{
        text-transform: uppercase;
    }
}

.ModalActionTransfer{
    .AttachFile{
        label{
            text-decoration: underline;
        }
    }
    .form-control.is-invalid{
        background-image: none;
    }
}

.ModalImagePayoutApproval{
    .modal-footer{
        border: none;
        padding: 0;
        > button {
            position: absolute;
            font-size: 0;
            color: #fff;
            border: none;
            top: -20px;
            right: -20px;
            height: 38px;
            width: 38px;
            background: #303238;
            border-radius: 50%;
            text-align: center;
            padding: 0;
            &:before{
                font-size: 16px;
                content: "\e646";
                font-family: 'themify';
            }
        }
    }
}

.PayoutCopyEmail {
    img{
        min-width: 15px;
    }
}
