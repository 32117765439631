.SelectInputSearchProductLine {
    > div{
        width: 100%;
        border-left: 1px solid #ccc;
        > div{
            border: none;
            background-color: transparent;
            box-shadow: none;
            ~ div{
                background-color: #fff;
                box-shadow: 0 0 0 1px #ccc;
            }
        }
        .css-1rhbuit-multiValue{
            display: none;
        }
    }
}


.InputSearchProductLine {
    .SearchIcon {
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    .SearchInput {
        padding-right: 40px;
    }

    .SelectTypeProductLine{
        border-radius: 4px 0 0 4px;
        margin-right: -2px;
        z-index: 2;
        border-color: #CED4DA;
        position: relative;
    }
}
