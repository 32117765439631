.StoreDetails {

    .StatusContainer {
        display: flex;

        .Status {
            align-self: center;
            margin-right: .5rem;

            &.active {
                background-color: $default-primary;
            }
        }
    }

    .StorefrontStatusContainer {
        display: flex;

        .StorefrontStatus {
            align-self: center;
            margin-right: .5rem;

            &.active {
                background-color: $default-primary;
            }
        }
    }
    .DomainPrimary {
        display: flex;
        
        .Domain{
            align-self: center;
            margin-left: .5rem;
        }
            &.active {
                background-color: $default-primary;
            }
        
    }

    .text--right {
        display: flex;
        justify-content: space-between;
    }
}
@media screen and (min-width: 1200px) {
    .modal-xl {
        max-width: 1300px;
    } 
}