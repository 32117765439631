.StatusColumn {
    &.active, &.done {
        > span {
            background-color: $default-primary;
        }
    }
    &.error {
        > span {
            background-color: #ff0000;
        }
    }
    &.cancelled {
        > span {
            background-color: $default-warning;
        }
    }
    &.processing {
        > span {
            background-color: $default-info;
        }
    }
}
