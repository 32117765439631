html, html a, body {
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

a {
    transition: all 0.3s ease-in-out;
}

body {
    font-family: $font-primary;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    color: $default-text-color;
    line-height: 1.5;
    letter-spacing: 0.2px;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-secondary;
    letter-spacing: 0.5px;
    line-height: 1.5;

    a {
        font-family: $font-secondary;
    }

    small {
        font-weight: 300;
        color: lighten($default-dark, 5%);
    }
}

p {
    font-family: $font-primary;
    line-height: 1.9;
}

.lead {
    font-size: 18px;
}

ul {
    margin-bottom: 0;
    list-style: none;
}

a {
    color: $default-info;

    &:hover,
    &:focus {
        text-decoration: none;
        color: darken($default-info, 10%);
    }

    &:focus {
        outline: none;
    }

    &.text-gray {
        &:hover,
        &:focus,
        &.active {
            color: $default-dark !important;
        }
    }
}

:focus {
    outline: none;
}

hr {
    border-top: 1px solid $border-color;
}

.form-control {
    &:focus {
        box-shadow: none;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9fafb;
}

.table td {
    vertical-align: middle;
}

.colActions {
    width: 1px;
}

img {
    max-width: 100%;
    height: auto;
}
.invalid-input {
    border: 1px solid #dc3545;
}
.error-message {
    color: #dc3545;
    font-size: 80%;
}
.react-datepicker__close-icon {
    &::after {
        line-height: 0 !important;
        font-size: 11px !important;
    }
}
.hidden {
    opacity: 0;
}

button {
    &:disabled {
        cursor: not-allowed;
    }
}