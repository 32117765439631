.ListVariantImages {
    width: 620px;
    max-width: calc(100vw - 30px);

    .modal-body {
        min-height: 150px;
    }

    .SaveVariants {
        min-width: 120px;
    }

    .UploadVariantImages {
        .BorderWrapper {
            position: relative;
            padding-bottom: 100%;
            background-color: #e9ecef;
        }

        .BorderInner {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            transform: translateY(-50%);
        }
    }

    .BorderWrapper {
        i {
            font-size: 26px;
        }
    }
}
