.ProductImagesList{
    grid-column: 4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.ProductImagesItem {
    position: relative;
    .ProductImageView {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        overflow: hidden;
        opacity: 0;
    }
    .ImageWrapper{
        background-size: 100%;
        padding-top: 150%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .RemoveImage {
        cursor: pointer;
        position: absolute;
        top: -6px;
        right: -6px;
        border-radius: 50%;
        color: #fff;
        background-color: #C82333;
        width: 20px;
        height: 20px;
        font-size: 8px;
        text-align: center;
        line-height: 21px;
        &:before{
            margin-right: 1px;
        }
    }
}
