.ZoneRateItem {
    &:hover {
        background: rgba(0, 0, 0, .0625);
    }
}

.ActionZoneItem{
    .ActionZoneItemEdit{
        &:after{
            content: '|';
            margin: 0 8px;
            color: #ccc;
        }
    }
}
