.z-index-10000 {
    z-index: 10000 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.UploadInput {
    position: relative;

    input[type=file] {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }

}

.Disabled {
    opacity: .65;
    pointer-events: none;
    cursor: initial;
}

.HeadingLine {
    position: relative;
    display: inline-block;

    &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 25%;
        background-color: #303238;
        bottom: -6px;
        left: 0;
    }
}

.fs-14 {
    font-size: .875rem;
}

.fs-16 {
    font-size: 1rem;
}

.fs-18 {
    font-size: 1.125rem;
}

.fs-20 {
    font-size: 1.25rem;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;

    &:hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}

.FeatureImage {
    .ImageWrapper {
        position: relative;
        padding: 0 0 100% 0;
        background-repeat: no-repeat;
        background-position: center;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            overflow: hidden;
            opacity: 0;
        }
    }
}

.w-1px {
    width: 1px;
}

.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}
.table-col-5 {
    width: 5%;
}

.table-col-10 {
    width: 10%;
}

.table-col-15 {
    width: 15%;
}

.table-col-20 {
    width: 20%;
}

.table-col-25 {
    width: 25%;
}

.table-col-30 {
    width: 30%;
}

.table-col-35 {
    width: 35%;
}

.table-col-40 {
    width: 40%;
}

.table-col-45 {
    width: 45%;
}

.table-col-50 {
    width: 50%;
}

.table-col-55 {
    width: 55%;
}

.table-col-60 {
    width: 60%;
}

.table-col-65 {
    width: 65%;
}

.table-col-70 {
    width: 70%;
}

.table-col-75 {
    width: 75%;
}

.table-col-80 {
    width: 80%;
}

.table-col-85 {
    width: 85%;
}

.table-col-90 {
    width: 90%;
}

.table-col-95 {
    width: 95%;
}

.table-col-100 {
    width: 100%;
}

.font-weight-500 {
    font-weight: 500 !important;
}
