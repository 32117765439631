.ProductApprovalPage .filter{
  > div{
    margin-bottom: 10px !important;
  }
}

@include media-breakpoint-down(xs) {
  .ProductApprovalPage .SelectInputStore > div{
    min-width: 220px;
  }
  .ProductApprovalPage .filter{
    > div{
      flex-flow: column;
      align-items: baseline !important;
      label{
        margin-bottom: 8px !important;
      }
    }
  }
}
