.ProductEditContainer {
    //margin: -20px -29px;

    .Header {
        display: block;
        width: 100%;

        h1 {
            margin: 0;
        }
    }

    //.PageContent {
    //    padding: 20px 30px;
    //}

    .SectionInner {
        padding: 30px 20px !important;
    }

    @include media-breakpoint-down(md) {
        margin: 0;

        .StickyActions {
            display: none;
        }
    }
}
@include media-breakpoint-down(xs) {
    .ProductEditBottomContainer{
        button{
            font-size: 14px;
            padding: 8px;
        }
    }
}