.ShipmentReportContainer {
    .ShipmentReportPage {

        .TableFilter {
            position: relative;
            z-index: 10;
        }
        .TableLength {
            margin-left: 15px;
        }
        .Filter {
            margin-left: 5px;
            .Total {
                text-align: right;
                width: 100px;
            }
            @media (max-width: 1200px) {
                justify-content: start!important;
                .input-group {
                    justify-content: start!important;
                    > div {
                        margin-left: 10px!important;
                    }
                }
            }
        }
        .table-responsive {
            max-height: 800px;
        }
        .table {
            box-shadow: inset 1px 0 0 #dee2e6;
            table-layout: fixed;
            width: max-content;
            thead {
                position: sticky;
                top: 0;
                z-index: 1;
                box-shadow: inset -1px -1px 0 #dee2e6;
                background-color: #e9ecef;
                tr {
                    th {
                        border: none;
                    }
                    &:first-child {
                        th {
                            box-shadow: inset -1px -1px 0 #dee2e6;
                        }
                        th:first-child {
                            position: sticky;
                            left: 0;
                            background-color: #e9ecef;
                        }
                        th:nth-child(2) {
                            position: sticky;
                            left: 248px;
                            background-color: #e9ecef;
                        }
                    }
                    &:last-child {
                        th {
                            box-shadow: inset -1px 1px 0 #dee2e6;
                        }
                    }
                }
            }
            .thead-light th {
                padding: 0.5rem;
                vertical-align: middle;
            }
            .ShipmentRule {
                font-size: 13px;
                line-height: 1rem;
            }

            .ShipmentReportTableBody {
                tr td {
                    border-right: none;
                    border-left: none;
                    box-shadow: inset -1px 0 0 #dee2e6;
                    width: 105px;
                    &:first-child {
                        box-shadow: inset -1px 0 0 #dee2e6;
                        position: relative;
                        left: -1px;
                        width: 248px;
                        word-break: break-word;
                        &:before {
                            position: absolute;
                            top: 0;
                            left: 1px;
                            width: 1px;
                            height: 100%;
                            content: "";
                            background-color: #dee2e6;
                        }
                    }
                    &:first-child, &:nth-child(2) {
                        position: sticky;
                        background-color: #ffffff;
                    }
                    &:nth-child(2) {
                        left: 247px;
                        width: 70px;
                        box-shadow: inset -1px 0 0 #dee2e6;
                    }
                    .text-danger,
                    .text-primary {
                        cursor: pointer;
                        transition: 0.3s ease-in-out;

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                    p,
                    span {
                        line-height: 1rem;
                        font-size: 14px;
                    }
                }
                tr {
                    &:hover {
                        td {
                            background-color: #eeeeee!important;
                        }
                    }
                }
            }
        }
    }
}

.DelayShipmentModal {
    .table-responsive {
        overflow-y: auto;
        max-height: 500px;
    }
    @media (max-width: 767px) {
        .table-responsive {
            max-height: 350px;
        }
    }
    @media (max-width: 576px) {
        .table-responsive {
            max-height: 200px;
        }
    }
}

.ShipmentReportPage .PagePagination,
.DelayShipmentModal .PagePagination {
    padding-top: 1rem;
}

