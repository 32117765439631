.RateEditPage {
    .ErrorMessage {
        background-color: rgba(220, 53, 69, 0.05);
        list-style: disc;
        border-left: solid 5px #dc3545;
        padding-left: 40px !important;
    }
}
.ActionRateItem{
    .ActionRateItemEdit{
        &:after{
            content: '|';
            margin: 0 8px;
            color: #ccc;
        }
    }
}
