.PlanFeatures {
    margin: 1rem -1rem 0;
    padding: .5rem 1rem;
    border-top: 1px solid #ced4da;

    .Title {
        font-weight: 500;
    }

    .FeaturesTable {
        width: 100%;
        overflow: auto;
        max-height: 40vh;

        .FeaturesWrapper {
            border-collapse: collapse;
            border-radius: 4px;
            width: 100%;
            border: 1px solid #ced4da;

            tbody {
                tr {
                    td {
                        padding: .375rem .75rem;
                        border: 1px solid #ccc;
                    }

                    td.FeatureTitle {
                        width: 30%;
                        font-weight: 500;
                    }

                    td.FeatureValue {
                        width: 40%;

                        .TextValue {
                            width: 100%;

                            label {
                                width: 30%;
                            }

                            input {
                                padding: .375rem .75rem;
                                border: 1px solid #ced4da;
                                border-radius: .25rem;
                                width: 70%;

                                &:focus {
                                    border-color: #0f9aee;
                                }
                            }
                        }
                    }

                    td.FeatureSupport {
                        width: 30%;
                    }
                }
            }
        }
    }
}
