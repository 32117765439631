.TableConfig {
    .Header {
        display: flex;
        justify-content: space-between;
        margin: 8px 0;
        @media (max-width: $breakpoint-md) {
            flex-wrap: wrap;
            gap: 8px;
        }

        .HeaderRight {
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 8px;
            .SearchBar {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 40%;

                .SearchInput {
                    border-radius: 21px;
                    border-width: 1px;
                    margin-right: -2.75rem;
                    width: 100%;
                }

                .SearchIcon {
                    padding: 0.75rem 1rem 0.45rem;
                }
            }
        }
    }
    .Body {
        overflow-y: auto;
        max-height: 500px;
        thead {
            position: sticky;
            top: -1px;
        }
    }
}