.AccountStoreDetails {
    flex: 1;
    flex-wrap: wrap;
    &:not(:last-child) {
        margin-right: 1rem;
    }
    .Status {
        &.active {
             background-color: $default-primary;
        }
    }
}