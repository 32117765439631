.PFAlert {
  position: relative;
  padding: 12px 40px 12px 74px;
  background-color: #fff;

  .Dismiss {
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }

  .Message {
    line-height: 1.9;
  }

  .IconType {
    position: absolute;
    left: 24px;
    top: 10px;
    font-size: 32px;
    margin-right: 16px;
    line-height: 1;
  }

  &.warning {
    .IconType {
      color: #ffc107;
    }
  }

  &.info {
    .IconType {
      color: #17a2b8;
    }
  }

  &:not(.banner) {
    //padding: 16px 30px;
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 3px 4px 3px 0 rgba(63,63,68,.05);

    &.warning {
      border-left: solid 6px #ffc107;
    }

    &.success {
      border-left: solid 6px #1da362;
    }

    &.info {
      border-left: solid 6px #17a2b8;
    }
  }

  &.banner {
    //padding: 8px 30px;

    &.error {
      background-color: crimson;
      color: #fff;
    }

    &.success {
      .IconType {
        color: #155724;
      }
      color: #155724;
      background-color: #d4edda;
    }
    &.warning {
      background-color: blanchedalmond;

      .IconType {
        color: #ffc107;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .PFAlert {
    padding: 20px 20px 20px 20px;
    .Dismiss {
      top: 8px;
      right: 8px;
    }
    .Message{
      line-height: 1.5;
      font-size: 15px;
    }
    .IconType{
      display: none;
    }
  }
}


.PFAlert{
  &.ProductWasReject,
  &.ProductWasTakenDown{
    background-color: #F9E3E5;
    border-left: solid 4px #DC3545 !important;
    padding: 16px 55px;
    .IconType{
      left: 20px;
      margin-right: 8px;
      top: 8px;
    }
    .TextMesInfo{
      margin-top: 4px;
      h6{
        color: #2F3230;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 20px;
      }
      p{
        font-size: 14px;
        color: #6E7370;
        margin: 0;
        line-height: 20px;
      }

    }
  }
  &.ProductWasReject{
    .IconType{
      top: 12px;
    }
  }
  @include media-breakpoint-down(xs) {
    &.ProductWasReject,
    &.ProductWasTakenDown{
      padding: 20px;
    }
  }
}
