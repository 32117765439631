.OrderRefund{
    .OrderRefundContent{
        background-color: #fff;
        padding: 30px 15px;
        border: solid 1px rgba(0, 0, 0, 0.0625);
        border-radius: 4px;
    }
    .Filter{

        .SelectInputStore > div{
            min-width: 380px;
        }
        .SelectInputStore{
            select{
                padding: 7px 30px;
                border-radius: 0px;
            }
            .css-yk16xz-control{
                border-radius: 0px;
            }
        }
        .InputStore{
            label{
                margin-bottom: 0;
            }
        }
        .PaymentStatus{
            background: white;
            border-radius: 4px;
        }
        .OrderNumber{
            min-height: 38px;
            border: 2px solid #dee2e6;
            border-radius: 4px;
            padding: 2px 8px;
        }
    }
}
