.DetailOrderRefund{
   .container-fluid{
        background-color: #fff;
        padding: 30px 15px;
        border: solid 1px rgba(0, 0, 0, 0.0625);
        border-radius: 4px;
        margin-top: 0.5rem;
        .table{
            .note{
                max-width: 250px;
            }
            .TextareaNote{
                width: 100%;
                height: 38px;
            }
            td {
                vertical-align: top
            }
        }
   }
   .BackNavigationButton{
       .StatusOrderReund{
           font-size: 11px;
           border-radius: 8px;
           padding: 2px 4px;
       }
   }
}
