.ClustersPage {
    .TopBar {
        display: flex;
        .Action {
            width: 60%;
        }
        .SearchBar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 40%;
            .SearchInput {
                border-radius: 21px;
                border-width: 1px;
                margin-right: -2.75rem;
                width: 100%;
            }
            .SearchIcon {
                padding: 0.75rem 1rem 0.45rem;
            }
        }
        @media (max-width: $breakpoint-md) {
            flex-wrap: wrap;
            .SearchBar {
                margin: 0.5rem 0;
                width: 100%;
            }
        }
    }

    .Filter {
        display: flex;
        > * {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}
.ClusterEnvUpdater {
    .EnvFilter {
        select {
            padding-right: 30px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}