#PFToast {
  > div[class^="Toast_"] {
    position: fixed;
    z-index: 5500;

    > div:not(:last-child) {
      margin-bottom: 8px;
    }

    .close {
      margin-left: 30px;
    }
  }

  .Toast_top {
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Toast_bottomLeft {
    bottom: 20px;
    left: 20px;
  }

  .Icon {
    font-size: 20px;
    margin-right: 8px;
  }

  .FullWidthToast {
      max-width: 100% !important;
  }

}
