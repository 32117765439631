.ProductAttributeEditor {
    form {
        width: 220px;
    }
}
.ProductStoreModes{
    .form-store-mode{
        position: relative;
    }
    .icon-save{
        -webkit-animation: spin 1s infinite linear;
        animation: spin 1s infinite linear;
        top:0%;
        position: absolute;
        z-index: 2;
        transform: translate(0%, 0%);
        right:0%;
        color: #007BFF;
    }
    .save-store-mode{
        opacity: .5;
    }
    .spinner-border{
        color: #007BFF !important;
        top: 0;
        position: absolute;
        z-index: 2;
        right: 0;
    }
}

.ProcessingTime{
    div{
        min-width: 90px;
        margin-right: 15px;
    }
}
