.NavbarLeft {
    .NavbarLeftInner {
        flex-direction: column;
        min-height: 100%;

        .BottomMenu {
            margin-bottom: 10px;
        }
    }
}
