.PricingPage {
    .TopMenuButtons {
        display: flex;
        justify-content: flex-end;
        margin: 0 0 1rem;

        button {
            margin: 0 1rem;

            &:last-child {
                margin: 0;
            }
        }
    }

    .PlanWrapper {
        margin-bottom: 1rem;
        .ViewButton {
            float: right;
        }
    }
}
