td.ProductColArtworks {
    .ProductArtworkItem {
        position: relative;

        .Side {
            position: absolute;
            z-index: 1;
            left: 5px;
            top: 5px;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            padding: 0 5px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .6);
        }
    }
}
