.ProductApprovalPage .SelectInputStore{
    > div{
        min-width: 380px;
        > div{
            border: none;
            border-left: 1px solid #DBDBDB;
            border-radius: 0;
            background: none;
            box-shadow: none;
            ~ div{
                box-shadow: 0 0 0 1px #ccc;
                border-left: 0;
                background-color: #fff;
            }
        }
    }
}

.VariantLimitSelector{
    select {
        padding-right: 25px;
    }
}
