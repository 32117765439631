@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes FadeOutRemoveVariantImage {
    0% {
        opacity: 1;
    }

    90% {
        opacity: .2;
        position: static;
        width: 100px;
    }

    95% {
        opacity: .1;
        position: absolute;
        top: auto;
        left: -5000px;
        width: 50px;
    }

    100% {
        opacity: 0;
        position: absolute;
        left: -10000px;
        top: auto;
        width: 0;
    }
}

@keyframes PlaceHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.AnimatedPlaceHolderShimmer {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: PlaceHolderShimmer;
    animation-timing-function: linear;
    background: rgba(160, 160, 160, 1);
    background: linear-gradient(135deg, rgba(160, 160, 160, 0.1) 8%, rgba(160, 160, 160, 0.3) 18%, rgba(160, 160, 160, 0.1) 33%);
    background-size: 800px 104px;
    position: relative;
}

@keyframes Flash {
    0%, 50%, 100% {
        opacity: 1;
    }
    25%, 75% {
        opacity: 0;
    }
}

.processing-animation {
    animation: progress-bar-stripes 1s linear infinite;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    background-color: #007bff;
}

.pending-animation {
    animation: progress-bar-stripes 1s linear infinite;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    background-color: #17a2b8;
}

@keyframes Pulse {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: -135% 0%;
    }
}

