.AutoComplete {
    position: relative;
    .CustomInput {
        position: relative;
        .InputAutoComplete {
            padding-right: 35px;
        }
        .Spin {
            position: absolute;
            right: 10px;
            top: 30%;
            // transform: translateY(-50%);
        }
    }

    .Suggestions {
        position: absolute;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        border-top: 1px solid #d4d4d4;
        background-color: #f7f7f7;
        border-radius: 0 0 10px 10px;
        max-height: 300px;
        overflow-y: scroll;
        li {
            list-style: none;
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #d4d4d4;
            }
        }
    }
}
