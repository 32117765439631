.FeaturesModal {
    .ModalBodyWrapper {
        .NewFeature {
            display: flex;
            flex-wrap: wrap;
        }

        .ListFeatures {
            .Feature {
                border: 1px solid #dee2e6;
                padding: 1rem;
                border-radius: 4px;
                margin: .5rem 0;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;

                &:last-child {
                    margin-bottom: 0;
                }

                .FeatureNameForm {
                    width: 70%;

                    .FeatureName {
                        width: 100%;
                        padding: .375rem .75rem;
                        margin-left: -.75rem;
                        border: 1px solid transparent;
                        border-radius: 4px;

                        &:hover {
                            border-color: #dee2e6;
                        }

                        &:focus {
                            border-color: #0f9aee;
                        }
                    }
                }

                .Remove {
                    cursor: pointer;
                    font-size: 1.25rem;

                    &:hover {
                        color: #0f9aee;
                    }
                }

                .ActiveWrapper {
                    margin-left: 1rem;
                }
            }
        }
    }
}
