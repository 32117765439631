.Tooltip3 {
  position: relative;
  cursor: default;
  &:hover {
    .TooltipContent {
      opacity: 1;
      visibility: visible;
    }
  }

  .TooltipContent {
    position: absolute;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    padding-top: 10px;
    transition: all .3s ease;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0;
    }
  }

  .ContentInner {
    padding: 4px 15px 6px;
    background: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    font-size: 13px;
    line-height: 1.8;
    border-radius: 4px;
    font-weight: 400;
  }

  &.Top {
    .TooltipContent {
      bottom: calc(100% + 4px);
      left: 50%;
      transform: translateX(-50%);
      &::after {
        bottom: -6px;
        left: 50%;
        margin-left: -5px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid rgba(0, 0, 0, 0.9);
      }
    }
  }

  &.Bottom {
    .TooltipContent {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        top: 4px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid rgba(0, 0, 0, 0.9);
      }
    }
  }

  &.Right {
    .TooltipContent {
      left: calc(100% + 1.5rem);
      top: 50%;
      transform: translateY(-50%);
      &::after {
        left: -3px;
        margin-top: -5px;
        top: 50%;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid rgba(0, 0, 0, 0.9);
      }
    }
  }
  &.Left {
    .TooltipContent {
      right: calc(100% + 1.5rem);
      top: 50%;
      transform: translateY(-50%);
      &::after {
        right: -6px;
        left: auto;
        transform: none;
        margin-top: -5px;
        top: 50%;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid rgba(0, 0, 0, 0.9);
      }
    }
  }

}
