.ProductAttributeItem {
    .ListAttributes {
        .Name {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 1px;
                height: 16px;
                background-color: #fff;
                right: 0;
                top: 0;
            }
        }

        .IconRemove {
            display: inline-block;

            &:hover {
                transform: scale(1.4);
            }
        }

        .Item {
            position: relative;
        }
    }
    .SettingCheckbox{
        margin-bottom: 0;
    }
}
