.VariantImage {
    width: 100px;

    &.removing {
        animation: FadeOutRemoveVariantImage;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }
}
