.ProductMockupHeader{
    > div{
        margin-bottom: 10px;
    }
    @media (max-width: 767px) {
        flex-flow: column;
        justify-content: end !important;
        align-items: end !important;
        .SearchProductMockup{
            min-width: 280px;
        }
        > div{
            width: 100%;
            margin: 0 0 10px !important;
        }
    }
}

.SearchProductMockup {
    position: relative;
    min-width: 310px;
    .SearchIcon{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .InputSearchProductMockup{
        min-width: 240px;
    }
}





.ProductDescriptionMockup{
    .EditorApp{
        max-height: 300px;
        overflow-y: auto;
    }
    
}
.LabelSearchMockup{
    min-width: 100px;
}
.WidthSpanStt{
    width: 25px;
}