.PayoutFilter {
    .SelectInputStore > div {
        min-width: 380px;
        > div {
            border: none;
            border-left: 1px solid #DBDBDB;
            border-radius: 0;
            background: none;
            box-shadow: none;
            background: #fff;
        }
    }
    select{
        height: 42px;
    }
}

.PayoutFilterID{
    input{
        height: 42px;
        min-width: 280px;
        border: 1px solid #DEE2E6;
    }
}
