.TableFilter {
    margin-bottom: 20px;
    width: 100%;
    padding: 2px;

    .TableLength {
        color: #313435;
        font-weight: 400;
        max-width: 300px;
        select {
            border: 1px solid rgba(0, 0, 0, 0.0625);
            border-radius: 2px;
            box-shadow: none;
            height: 40px;
            font-size: 14px;
            margin-left: 5px;
            margin-right: 5px;
            color: #303238;
            transition: all 0.2s ease-in;
        }
    }
    .TableLengthCustom {
        max-width: 240px;
    }

    .SelectWrapper {
        border: 1px solid #ced4da;
        border-radius: 5px 0 0 5px;

        select {
            min-height: 36px;
            cursor: pointer;
            border: transparent;
            background-color: #f9fafb;
            border-radius: 5px 0 0 5px;
        }

        .input-group-text {
            border: none;
        }
    }

    .InputWrapper {
        width: 400px;

        > i {
            top: 12px;
            left: 12px;
        }

        .InputSearch {
            // height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .InputWrapperCustom {
        width: auto;
    }

    .TotalEntity {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .Entity {
            font-weight: 700;
        }
    }
}

.TableLoading {
    td {
        vertical-align: middle !important;
        height: 60px;

        .Cell {
            width: 100%;
            height: 10px;
        }

        .ImageCell {
            height: 60px;
        }
    }
}

.SearchInput {
    position: relative;
    min-width: 310px;
    &.LongSearch {
        min-width: 380px;
    }
    .SearchIcon{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}
