.DepositsTable {
    .DepositsHeader {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 1rem 0;

        .DepositsTotal {
            .TotalNumber {
                font-weight: 500;
            }
        }
    }
}

.DepositDuplicate{
    background-color: #FDF4CD !important;
    border-top: 1px solid rgba(0,0,0,0.05);
}