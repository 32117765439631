.UpsellEdit {
  .Title {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
    align-self: center;
  }

  table.table {
    tbody {
      td {
        vertical-align: top;
      }
    }
  }
}
