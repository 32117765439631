.PFTableV2 {
    .BulkActionsManagement {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        color: #333;
    }

    .LinkSelect {
        color: #007bff;
        margin-left: 4px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .DropdownActions {
        margin-right: 16px;

        .dropdown-toggle {
            color: #333;
            background-color: #fff;
            border-color: #ccc;

            &:hover, &:active {
                color: #333;
                background-color: #e6e6e6;
                border-color: #adadad;
            }
        }

        &.show {
            .dropdown-toggle {
                color: #333;
                background-color: #e6e6e6;
                border-color: #adadad;
            }
        }
    }

    .SelectorHead {
        width: 1%;
        white-space: nowrap;
    }

    .PlaceholderCell {
        animation: table-loading-placeholder 1s infinite ease-in-out;
        min-height: 10px;
        margin: 8px 0;
    }
}
