.ArtworksListBySide {
    .groupAccodion {
        -webkit-transform: all 0.3s;
        -moz-transform: all 0.3s;
        -ms-transform: all 0.3s;
        -o-transform: all 0.3s;
        transform: all 0.3s;
        &.shortInfo {
            max-height: 430px;
            overflow: hidden;
            position: relative;
            &:after {
                position: absolute;
                width: 100%;
                height: 115px;
                bottom: 0;
                left: 0;
                z-index: 3;
                content: "";
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
                background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, #f8f9fa 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,#f8f9fa 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,#f8f9fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

            }
        }
    }
    .ArtworkItem {
        background-color: #fff;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        img {
            object-fit: contain;
        }
    }
}
