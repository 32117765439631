.StoreDetailPage {
    .BackButton {
        > span {
            margin-left: .5rem;
        }
    }

    .StoreDetailInner {
        margin-top: 1rem;
        position: relative;

        .Wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;

            .StoreRightPanel {
                margin-bottom: 1rem;
            }
            .StoreLeftPanel {
                margin-bottom: 1rem;
            }
        }

        .StoreRightPanel {
            margin-bottom: 1rem;
        }

        .disable {
            position: relative;
            .list-group-item {
                &.restoreDatabase {
                    position: relative;
                    z-index: 10;
                    box-shadow: 1px 5px 7px rgba(0,0,0,0.05);
                }
            }
            &:before {
                background-color: rgba(0, 0, 0, 0.3);
                content: "";
                z-index: 1;
                top: 0;
                width: 100%;
                left: 0;
                height: 100%;
                position: absolute;
            }
        }
    }
}
