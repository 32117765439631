
.JsonEditorShared{
    .jsoneditor{
        border: 1px solid rgba(0, 0, 0, 0.0625);
    }
    .jsoneditor-tree{
        background-color: #fff;
    }
}

