.ProductEditContainer {
    .PersonalizedBanner {
        background-color: #e1f3f5;
        border-left: 4px solid #47b2c1;

        .ti-info-alt {
            background-color: #88e1ee;
            color: #fff;
            border-radius: 60%;
            border: 5px solid #88e1ee;
        }
    }
}

.design-container {
    .panel {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .panel-elements {
            overflow-y: auto;
            @media (min-width: 768px) {
                padding-right: 25px;
            }
            @media (max-width: 768px) {
                height: initial;
                max-height: 500px;
                margin: 1em 0;
            }

            .design-panel {
                border-left: 3px solid #fff;
                border-top: none;
                border-bottom: 1px solid #E9EEEC;

                .text-title {
                    margin-left: 7px;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .wrap-item {
                    margin-left: 50px;
                    max-height: 0;
                    overflow: hidden;
                    transition: all .5s ease;
                }

                .panel-input {
                    position: relative;

                    .select__menu {
                        top: unset;
                        bottom: 100%;

                        .select__menu-list {
                            max-height: 200px;
                        }
                    }

                    .custom-file-label {
                        padding: 0.375rem 1.25rem;
                        border: none;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .custom-icon {
                        display: inline-flex;
                        border-radius: 50%;
                        background-color: #666;
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        .ti-close {
                            font-size: 10px;
                            color: #fff
                        }
                    }

                    .no-file {
                        opacity: 0.8;
                    }

                    .panel-extension {
                        font-size: 14px;
                    }

                    .wrap-checkmark {
                        margin-bottom: 20px;
                    }

                    .input-color {
                        width: 30px;
                        height: 30px;
                    }

                    .text-color {
                        align-self: center;
                    }
                }

                //custom combobox
                .rw-combobox {

                    .rw-widget-input {
                        border: none;
                        outline: none;
                        width: 100%;
                        flex: 1 1;
                    }

                    .rw-popup-container {
                        top: unset;
                        bottom: 75%;
                        @media screen and (min-width: 1025px) {
                            left: 30%;
                        }

                        &.rw-popup-transition-exited {
                            .rw-popup-transition {
                                transform: translateY(100%);
                            }
                        }

                        .rw-state-selected {
                            color: #2F3230;
                            background-color: #F3F5F4;
                            border-color: #F3F5F4;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            &:after {
                                content: "";
                                display: inline-block;
                                width: 5px;
                                height: 10px;
                                border: solid #2F3230;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                &.pn-disabled {
                    .form-control {
                        background: #e9ecef;
                    }

                    div.form-control {
                        background: #e9ecef;
                    }

                    .wrap-checkmark ~ .form-control {
                        background: transparent;
                        border: none;
                    }
                }

                @media (min-width: 768px) {
                    .panel-title {
                        width: 40%;
                    }

                    .panel-input {
                        width: 60%;
                    }

                    .panel-flex {
                        display: flex;
                    }
                }

                @media (max-width: 767px) {
                    .table-col-5 {
                        width: 20%;
                    }
                }

                .frame-title {
                    height: 40px;
                    background: #F3F5F4;
                    border: 1px solid #D3DCD7;
                    box-sizing: border-box;
                    border-radius: 4px;

                    .ti-text, .ti-image {
                        color: #A9AFAB;
                    }
                }

                .input-file {
                    overflow: hidden;
                }

                .input-area {
                    resize: none;
                    height: 140px;
                }

                .input-checkbox {
                    width: 16px;
                    height: 16px;
                }

                .input-number {
                    width: calc(100% - 16px);
                    border: none;
                }

                &.disabled {
                    input, textarea, select, .select__control, div.form-control {
                        pointer-events: none;
                        background-color: #e9e9e987;
                    }

                    div.form-control {
                        input {
                            background-color: transparent;
                        }
                    }
                }

                &.active, &.has-error {
                    border-left: 3px solid #15CD72;

                    .wrap-item {
                        overflow: visible;
                        max-height: 600px;
                        margin-top: 1.5rem;
                    }

                    .type-background {
                        padding-top: 1.2rem !important;
                    }
                }
            }
        }

        .rw-combobox-input {
            z-index: initial;
        }

        @media (max-width: 768px) {
            padding: 0;
        }
    }

    .ti-info-alt {
        color: #47B2C1
    }

    .tooltip-wrap {
        z-index: 1;
        border: 1px solid #ddd;
        border-radius: 10px;
        color: #6E7370;
    }

    .btn-add {
        background-color: #15CD72;
        white-space: nowrap;
    }

    .btn-font {
        border: 1px solid #D3DCD7;
        white-space: nowrap;
    }

    #main-preview {
        .IconLoading {
            position: absolute;
            top: 1px;
            left: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            justify-content: center;
            align-items: center;
            background: #ffffffab;
            display: none;
        }

        .IconLoading.show {
            display: flex;
        }

        .text-area {
            display: inline-block;
        }
    }

    @media (max-width: 768px) {
        .btn-bottom {
            justify-content: space-between;
        }
    }

    //custom input
    .wrap-checkmark {
        display: block;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        font-size: 15px;
        user-select: none;

        .text-preview {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: #6E7370;
        }

        .custom-checkmark {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark:after {
                display: block;
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: 2px solid #15CD72;
            border-radius: 3px;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 2px;
                width: 5px;
                height: 10px;
                border: solid #15CD72;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
}

.design-layer {
    border: 1px solid #ddd;
    overflow: hidden;

    .design-tool {
        background-color: #2F3230;
        opacity: 0.8;
        height: 40px;
        margin-top: -40px;

        .mr-wrap-icon {
            margin-right: 38px;
            background-color: transparent;
        }

        .mr-icon {
            margin-right: 20px;
        }
    }

    .textInline {
        position: absolute;
        border: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
        background: none;
        outline: none;
        resize: none;
    }
}

.design-mask {
    background-color: #dee2e6;
    opacity: 0.1;
    z-index: 1;
}

@media screen and (max-width: 1200px) {
    .design-layer {
        .design-tool {
            padding: 0 5%;
        }
    }
}

@media screen and (max-width: 767px) {
    .design-container {
        .wrap-panel {
            flex-direction: column-reverse;
            margin-top: 1rem;
        }
    }

    .design-panel {
        .panel-input {
            .panel-textarea {
                white-space: nowrap;
                text-overflow: initial;
            }
        }
    }

    .design-layer {
        .design-tool {
            padding: 0 25px;
        }
    }
}

