.ProductGalleryImage {
    position: relative;
    padding: 8px;
    border: solid 1px #dee2e6;
    border-radius: 4px;
    z-index: 1;
    //overflow: hidden;

    &:first-child {
        grid-column: 1/span 2;
        grid-row: 1/span 2;
    }

    .Image {
        position: relative;
        width: 100%;
        padding-top: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .Uploading {
        position: relative;
        width: 100%;
        padding-top: 100%;

        .Content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .IconRemove {
        position: absolute;
        right: -10px;
        top: -14px;
        z-index: 1;
        font-size: 20px;
        cursor: pointer;
        color: #6c757d;
        transition: all .3s;

        &:hover {
            color: #dc3545;
        }

        i {
            background-color: #fff;
            border-radius: 50%;
        }
    }

    &.error {
        border-color: #dc3545;
    }
}
