.ChartContainer {
    margin-top: 1rem;
    .Charts {
        display: flex;
        width: 100%;
        @media (max-width: $breakpoint-md) {
            flex-wrap: wrap;
        }
    }
}
