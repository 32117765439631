.UpsellProductItem {
  .ThumbnailWrapper {
    max-width: 100px;
    max-height: 133px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .DiscountPrice {
    max-width: 150px;
  }

  button.Delete {
    background: none;
    border: none;
    outline: none;
    text-decoration: underline;
  }

  .ProductPrice.isSaleOff {
    .Origin {
      color: #333;
      text-decoration: line-through;
      font-size: 1.125rem;
      margin-right: 10px;
    }

    .Sale {
      font-size: 1.125rem;

      .Value {
        margin: 0 5px;
      }

      .SaveValue {
        color: #ee7500;
        white-space: nowrap;
        margin-left: 5px;
      }
    }
  }
}
