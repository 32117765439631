// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @General Media Query
// + @All Above Media Query
// + @All Under Media Query
// + @Between Two Devices Media Query

// ---------------------------------------------------------
// @General Media Query Mixin
// ---------------------------------------------------------

// Mixin used for custom rules that don't follow
// any of the following premade media queries.

@mixin mq($condition) {
    @media #{$condition} {
        @content;
    }
}

// ---------------------------------------------------------
// @All Above Media Query Mixin
// ---------------------------------------------------------

// Mixin used to match certain breakpoint
// and all devices above it.

@mixin from($breakpoint) {
    @media screen and (min-width: $breakpoint){
        @content;
    }
}

// ---------------------------------------------------------
// @All Under Media Query Mixin
// ---------------------------------------------------------

// Mixin used to match all devices under certain breakpoint.

@mixin to($breakpoint) {
    @media screen and (max-width: $breakpoint - 1px) {
        @content;
    }
}

// ---------------------------------------------------------
// @Between Two Devices Media Query Mixin
// ---------------------------------------------------------

// Mixin used to match the devices between 2 breakpoints.

@mixin between($start, $end){
    @media screen and (min-width: $start) and (max-width: $end - 1px) {
        @content;
    }
}
