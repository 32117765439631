.CountryItem {
    padding: 10px 0;

    .custom-control {
        padding-left: 30px;
    }

    .custom-control-label::before {
        left: -27px;
    }

    .custom-control-label::after {
        left: -27px;
    }
}
