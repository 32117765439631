.AnalyticsPage {
    .AnalyticsPageInner {
        .BoxContainer {
            .MetricBoxes,
            .AnalyticBoxes {
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }
}
