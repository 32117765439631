.tox-notifications-container{
    display: none;
}
.tox-statusbar__branding{
    display: none;
}

.is-invalid{
    .tox-tinymce{
        border: 1px solid #dc3545;
    }
}

