.ProductDisplayEdit {
    .Title {
        font-weight: 500;
        margin-bottom: 20px;
    }

    .Block {
        .CheckBox {
            margin-bottom: 0.5rem;
        }
    }
}
