.StoresPage {
    .StoresPageInner {
        margin-top: 50px;
        position: relative;
        background-color: hsl(0%, 0%, 100%);
        border-radius: 4px;
        border-width: 1px;
        .SearchBar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin-bottom: 0.5rem;
            .SearchInput {
                border-radius: 21px;
                border-width: 1px;
                margin-right: -2.75rem;
                width: 40%;
            }
            .SearchIcon {
                padding: 0.75rem 1rem 0.45rem;
            }
        }
        .ResetFilters {
            position: absolute;
            right: 0;
            background-color: #ffffff;
        }

        table {
            td {
                label {
                    font-weight: 600;
                }

                &.DetailColumn {
                    min-width: 200px;
                }
            }
        }

        .TableSettingRules {
            .TableHeader {
                th {
                    &:nth-child(2) {
                        width: 140px;
                    }
                    &:nth-child(3) {
                        width: 180px;
                    }
                    &:nth-child(5) {
                        width: 200px;
                    }
                    &:nth-child(6) {
                        width: 100px;
                    }
                    &:nth-child(7) {
                        width: 140px;
                    }
                }
            }
            td {
                &:nth-child(4) {
                    white-space: pre-line;
                    word-break: break-all;
                }
            }
        }
    }
    .CashbackContainer {
        .dropdown {
            button {
                color: #6c757d;
                background-color: transparent;
                border-radius: 0.25rem;
            }
        }
    }
    .ActionsContainer {
        .dropdown {
            button {
                color: #6c757d;
                background-color: transparent;
                border-radius: 0.25rem;
            }
        }
    }
}

.cashback-modal {
    .upload-file-valid,
    .upload-file-invalid {
        padding: 16px 40px 16px 30px;

        a {
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .upload-file-valid {
        background-color: rgba(233, 233, 233, 0.5);
        border-left: 5px solid #17a2b8;
    }
    .upload-file-invalid {
        background-color: rgba(220, 53, 69, 0.05);
        border-left: 5px solid #dc3545;
    }
}

.CashbackHistoryContainer {
    .CashbackHistoryTable {
        .cashback-log {
            color: #0f9aee;
            cursor: pointer;
            &:hover {
                color: #0c7bbe;
            }
        }
    }
}

.ImportLogsFilters  {
    .DateCustomFilter {
        > span {
            display: none!important;
        }
    }
    .SearchBar {
        position: relative;
        i {
            position: absolute;
            left: 12px;
            top: 10px;
            color: #8f8f8f;
        }
        input {
            padding: 4px 8px 4px 38px;
            height: auto;
        }
    }
}


.TableImportLogs {
    table {
        th:nth-child(3) {
            width: 150px;
        }
    }
}

.ImportActionButton {
    > .dropdown {
        > div {
            right: 0!important;
            left: auto!important;
            top: 40px!important;
            transform: none!important;
            button:focus,
            button:active,
            button.show {
                background-color: transparent !important;
                box-shadow: none !important;
                color: #333333;
                outline: none !important;
                box-shadow: none !important;
            }
        }
    }
}

.ImportRulesModal {
    ul {
        list-style: disc;
        padding-left: 34px;
    }
}
