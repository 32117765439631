.CustomTimeRangeFilter {
    .DateRangePicker {
        display: none;
    }

    &.isOpenEditor {
        .Preview {
            display: none;
        }

        .DateRangePicker {
            display: inline-block;
        }
    }
}
