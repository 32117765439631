.SelectedCountriesList{
    ul{
        padding-left: 0;
        margin-bottom: 0;
    }
}

.ListCountriesContainer {
    > ul {
        max-height: 70vh;
        overflow-y: auto;
    }
    ul{
        padding-left: 0;
        margin-bottom: 0;
    }
}

.InputSearchCountries {
    &.Searching {
        & ~ .ListCountriesContainer {
            .GroupCountries {
                border: none !important;

                > .CountryItem {
                    display: none;
                }

                > ul {
                    margin-left: 0 !important;
                }
            }
        }
    }

    .SearchIcon {
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    .SearchInput {
        padding-right: 40px;
    }
}

.CountryItem {
    padding: 10px 0;

    .custom-control {
        padding-left: 30px;
    }

    .custom-control-label::before {
        left: -27px;
    }

    .custom-control-label::after {
        left: -27px;
    }
}
