.VariantEdit {
    .Title {
        font-weight: 500;
        margin: 0;
        align-self: center;
    }

    .BulkEditVariantsPrices {
        max-width: 90%;
        display: flex;
        align-items: center;

        label {
            margin: 0 .5rem 0;
        }

        input {
            width: 16em !important;
            margin-right: 1rem;
        }
    }

    .Pagination {
        border: none;
        padding-top: 0;

        .page-item {
            margin-bottom: 0;
        }
    }
}
