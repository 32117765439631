.UpsellAddProductList {
  .SuggestCollections {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: .375rem .75rem;
      border-radius: .25rem;
      cursor: pointer;
      border: 1px solid transparent;

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      .ImageWrapper {
        position: relative;
        width: 60px;
        padding: 0 0 60px 0;

        .Thumbnail {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }

      .Title {
        margin-left: 10px;
      }
    }

    .SuggestCollection:hover {
      background-color: rgba(0, 0, 0, .075);
    }
  }
}
@include media-breakpoint-down(xs) {
  .ProductsSelected{
    .ProductSelectedInfo{
      min-width: 320px;
      .Thumbnail{
        min-width: 40px;
      }
    }
  }
}

