.UploadVariantImages {
    input[type=file] {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }
}

.SortableElementVariantImage {
    z-index: 9999;
    transition: none;

    .RemoveIcon {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 1.125rem;
    }

    .ImageWrapper {
        position: relative;
        width: 100%;
        flex-shrink: 0;
        padding: 0 0 100% 0;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}
