.DashboardContainer {
    .DashboardInner {
        min-height: 100vh;
        padding-left: $offscreen-size;
        transition: all 0.2s ease;
    }

    .MainContentInner {
        .SectionInner {
            background-color: #fff;
            padding: 30px 15px;
            border: solid 1px $border-color;
            border-radius: 4px;
        }
    }

    .MainContent {
        padding: 85px 20px 60px;
        min-height: 100vh;
        background-color: #f9fafb;

        @include to($breakpoint-md) {
            padding: 85px 5px 5px;
        }
    }

    .nav-link .Label {
        color: #fff;
    }

    &.isCollapsed {
        .nav-link .Label {
            display: none;
        }

        .NavbarLeft {
            width: $collapsed-size;

            .Title {
                display: none;
            }
        }

        .DashboardInner {
            padding-left: $collapsed-size;
        }

        .NavbarTop {
            width: calc(100% - #{$collapsed-size});
        }
        .MenuItem {
            &.HasChild {
                > .nav-link {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 7px;
                        right: 22px;
                        width: 0;
                        height: 0;
                        border-top: 6px solid #303238;
                        border-left: 6px solid transparent;
                    }
                }

                .SubMenu {
                    position: absolute;
                    left: calc(100% - 7px);
                    top: -30px;
                    background-color: #fff;
                    padding-left: 0;
                    border-radius: 4px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                    opacity: 0;
                    visibility: hidden;
                    transition: all .3s;
                    min-width: 300px;
                    max-height: unset;

                    ul {
                        padding: 0 20px;
                    }

                    .Title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-flow: wrap;
                        padding: 15px 20px;
                    }
                }
                .ToggleSubMenu {
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    position: absolute;
                    color: transparent;
                    z-index: 2;
                    &:before{
                        content: "\e649";
                    }
                }
                &.SubMenuActive {
                    .ToggleSubMenu {
                        &:before{
                            content: "\e64a";
                        }
                    }
                    .SubMenu {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .DashboardContainer {
        &.isCollapsed {
            .NavbarLeft {
                width: 0;
                overflow: hidden;
            }
            .DashboardInner{
                padding-left: 0;
            }
            .NavbarTop {
                width: 100%;
            }
        }
    }
}
