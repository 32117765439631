@import "filter";
@import "TableProductListing";
@import "ProductApproval";

.ArtworksProductApproval{
  .ArtworkUploadSide .UploadLabel{
    opacity: .5;
    cursor: default;
  }
}

.ProductApprovalGeneralEdit{
  .Editor .EditorApp{
    background-color: #e9ecef;
    border-top: 1px solid #CED4DA;
    border-radius: 4px;
    min-height: 240px;
  }
}

.ProductApprovalGalleryUploader .PFBoxUploader > label,
.uploadVariantImageProductApproval{
  opacity: .5;
  cursor: default;
}

.SearchProductApproval{
  .SearchIcon{
    position: absolute;
    z-index: 0;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .SearchInputApproval{
    position: relative;
    padding-right: 20px;
    height: 40px;
    width: auto;
    .SearchInput{
      min-width: 280px;
    }
  }
}
