.BalanceReportPageInner{
    .PagePagination{
        border: none;
    }
}

.BalanceReportTable,
.DetailReportTable{
    table{
        td,
        th{
            border: 1px solid #f1f1f1;
            vertical-align: middle;
        }
    }
}

.AccountingReportInput{
    .InputGroup{
        label{
            margin: 0;
        }
        .btn{
          min-width: 120px;
            height: 40px;
        }
        .DateRangePicker{
            .DateRangePickerInput{
                border-radius: 3px;
                line-height: 38px;
                padding-left: 5px;
            }
            .DateInput_input{
                font-weight: 400;
                letter-spacing: 0;
                color: #000;
            }
            input::placeholder {
                color: #000;
            }
            .DateRangePickerInput_arrow,
            .DateInput{
                background: none;
                vertical-align: baseline;
            }
            .DateRangePickerInput_clearDates{
                background: none;
                &:focus{
                  outline: none;
                }
                svg{
                    fill: #000;
                    height: 11px;
                    vertical-align: baseline;
                }
                &:hover{
                    svg{
                        fill: #ff0000;
                    }
                }
            }
        }
        .InputStore,.DateRangePicker .DateRangePickerInput{
            min-width: 260px;
            height: 40px;
        }
    }
}

.DetailReportPageInner {
    .SelectInputStore{
        > div{
            min-width: 380px;
            > div{
                border: none;
                border-left: 1px solid #DBDBDB;
                border-radius: 0;
                background: none;
                box-shadow: none;
                ~ div{
                    box-shadow: 0 0 0 1px #ccc;
                    border-left: 0;
                    background-color: #fff;
                }
            }
        }
    }
}

.TotalBalanceReport{
    display: flex;
    justify-content: flex-end;
    > span{
        word-break: break-word;
        &:after{
            content: '|';
            margin-right: 1rem;
            margin-left: 1rem;
            opacity: .2;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
        @media (max-width: 1280px) {
            margin-right: 1rem;
            margin-left: 1rem;
            &:after{
                display: none;
            }
        }
    }
    @media (max-width: 767px) {
        flex-flow: column;
        >span{
            margin-bottom: 12px;
        }
    }
}

@media (max-width: 480px) {
    .DetailReportPageInner {
        .InputStore {
            width: 100%;
            margin-right: 0 !important;
            flex-flow: column;
        }
        .SelectInputStore{
            flex-flow: column;
            width: 100%;
            select{
                width: 100%;
                background-color: transparent;
                border-radius: 0 !important;
                border-bottom: 1px solid #DEE2E6 !important;
            }
            > div{
                width: 100%;
                min-width: 100%;
                > div{
                    border-left: none;
                }
            }
        }
    }
    .AccountingReportInput{
        .InputGroup label{
            display: block;
            margin-bottom: 5px;
            margin-right: 0 !important;
            width: 100%;
        }
    }
}