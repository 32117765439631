.form-control{
    &:focus{
        box-shadow: none;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9fafb;
}

.table td {
    vertical-align: middle;
}

table .pretty {
    margin-right: 0;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
    box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

body .pretty input:checked ~ .state.p-primary label:after, body .pretty.p-toggle .state.p-primary label:after {
    background-color: #007bff !important;
}

.custom-control-label {
    cursor: pointer;
}

.custom-control{
    z-index: 0;
}

.quill {
    display: flex;
    flex-direction: column;

    .ql-container {
        font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-size: 14px;
        flex-grow: 1;
        color: #232323;
    }

    .ql-editor {
        line-height: 1.5;
        min-height: 200px;
        ul > li {
            list-style: disc;

            &::before {
                content: none;
            }
        }

        ol > li {
            list-style: decimal;

            &::before {
                content: none;
            }
        }

        ol li:not(.ql-direction-rtl), ul li:not(.ql-direction-rtl) {
            padding-left: 0;
        }
    }
}

.QuillNotification{
    border: 1px solid #ced4da;
    .ql-container.ql-snow{
        border: none;
    }
    .ql-toolbar.ql-snow{
        border-width: 0 0 1px;
        border-color: #ced4da;
    }
    .ql-snow .ql-tooltip {
        position: absolute;
        top: 0 !important;
        width: 100%;
        left: 0 !important;
        transform: none;
    }
}


select {
    background-image:linear-gradient(45deg, transparent 50%, #606060 60%), linear-gradient(135deg, #606060 40%, transparent 50%);
    background-position: calc(100% - 16px) 50%, calc(100% - 10px) 50%, 100% 0;
    background-size: 6px 6px,6px 6px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 30px 5px 15px;
    border-radius: 3px;
    border: 1px solid #dee2e6;
}
