.VariantTableWrapper {
    margin: 1rem 0;
    overflow: auto;

    .VariantTable {
        word-wrap: break-word;

        .Row {
            .Cell {
                border: 1px solid transparent;
                cursor: pointer;
                width: 100%;
                border-radius: 4px;
                background: transparent;
                padding: 0.375rem 0.75rem;
                margin: 0;
                outline: none;
                appearance: none;
                color: #495057;
                transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

                &:focus {
                    border-color: #80bdff !important;
                    background: #fff !important;
                }
            }

            &:hover {
                .Cell {
                    border-color: #d3dbe2;
                    background: #fff;

                    &:disabled, &.CellDisabled {
                        background: rgb(250, 251, 250);
                        border: 1px solid rgb(233, 238, 236);
                    }
                }
            }
        }
    }

    .DefaultVariantImage {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: 26px;

        i {
            opacity: .5;
            pointer-events: none;
        }
    }

    .table td {
        padding: .5rem;
        height: 50px;
    }

    .SelectAllVariants {
        position: relative;
        width: 100%;
        display: flex;

        .SelectAllCheckbox {
            width: calc(100% + .5rem);
        }

        .VariantsBulkEdit {
            z-index: 10;
            position: absolute;
            top: -3px;
            left: calc(100% + .5rem);

            .BulkEditOptions {
                position: absolute;
                list-style: none;
                padding: .375rem 0;
                margin: 0;
                width: max-content;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: #fff;
                border-radius: .25rem;
                top: calc(100% + 4px);

                li {
                    padding: .375rem .75rem;
                    width: 100%;
                    font-weight: normal !important;
                    font-size: 1rem !important;
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                        background: #2196f3;
                    }
                }
            }
        }
    }

    .VariantsPopupBackground {
        z-index: 9;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    .Row.isDefault {
        background-color: rgba($success, .08);

        td:last-child {
            position: relative;

            &::before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 4.375rem 2.5rem 0;
                border-color: rgba(255, 255, 255, 0) $success rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
                position: absolute;
                right: 0;
                top: 0;
            }

            &::after {
                content: "DEFAULT";
                position: absolute;
                top: 10px;
                right: 0;
                color: #fff;
                font-size: 10px;
                font-weight: bold;
                transform: rotate(30deg);
            }
        }
    }

    .VariantThumbnail {
        cursor: pointer;

        .FeatureImage {
            pointer-events: none;
        }
    }
}
