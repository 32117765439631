.ProductSearchItem {
    &:not(:last-child) {
        border-bottom: 1px solid #dee2e6;
    }

    &:hover {
        background-color: rgba(0, 0, 0, .075);
    }

    &.Inactive .Title {
        text-decoration: line-through;
        color: #6c757d;
    }

    .Thumbnail {
        display: inline-block;
        width: 42px;
        padding: 0 0 40px 0;
        background-size: cover;
    }

    label {
        padding: 12px 0;

        &::before, &::after {
            top: 50%;
            margin-top: -8px;
        }
    }
}
