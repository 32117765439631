.DateCustomFilter {
    .DateRangePicker {
        > div {
            display: inline-block;
            vertical-align: text-top;
        }
    }

    .DateRangePickerInput {
        border: solid 1px #ced4da;
        border-radius: 4px;
    }

    .DateRangePickerInput_clearDates_svg {
        height: 10px;
    }

    .DateRangePickerInput_clearDates {
        margin: 0;
        padding: 0;
        width: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        outline: none;

        &:hover, &:focus {
            background-color: transparent;
            color: $danger;

            .DateRangePickerInput_clearDates_svg {
                fill: $danger;
            }
        }
    }

    .DateInput_input {
        font-weight: 400;
        font-size: 1rem;
    }

    .DateInput__small {
        width: 100px;
    }

    .CustomFilterInner {
        border-radius: 4px;
        cursor: pointer;
    }

    .DateRangePickerInput__disabled {
        background-color: #f2f2f2;
        .DateRangePickerInput_clearDates {
            display: none;
        }
    }
}
