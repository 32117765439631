.affiliate-page {
    .card {
        padding: 20px;
        .left {
            width: 200px;
            select {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .right {
            width: 400px;
            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: -1px;
            }
        }
        .btn-create {
            max-width: 200px;
        }
        .table {
            .affiliate-name {
                p {
                    color: #5e5e5e;
                }
            }
            .affiliate-link {
                min-width: 500px;
                display: flex;
            }
            .btn-actions {
                min-width: 200px;
                .btn-success,
                .btn-danger {
                    width: 90px;
                }
            }
            .text-mute {
                color: #6c757d;
            }
        }
    }
}

.affiliate-detail {
    .card {
        padding: 20px;
        .invalid-input {
            border: 1px solid #dc3545;
        }
        .error-message {
            color: #dc3545;
            font-size: 80%;
        }
        .btn-customize {
            border-radius: 50%;
            padding: 5px 10px;
        }
    }
}
.widthdrawal-modal {
    .invalid-input {
        border: 1px solid #dc3545;
    }
    .error-message {
        color: #dc3545;
        font-size: 80%;
    }
}
.affiliate-profit-total {
    flex-wrap: wrap;
    .commission-left {
        span {
            width: 300px;
        }
        @media (max-width: 768px) {
            span {
                width: 150px;
            }
        }
    }
}
.history-table {
    overflow: auto;
    max-height: 600px;
}