.StoreSettingsPage {
    padding: 0 10rem;

    .StoreDetails {
        max-width: 100%;
        border-top: 1px solid $grey-500;
        margin: 1rem 1rem;
        padding: 0.5rem;
        border-bottom: 1px solid $grey-500;
        .StoreDetailsInner {
            display: flex;
            > * {
                flex: 1 1 33.333%;
                text-align: center;
                margin-left: 0.5rem;
            }
            @media (max-width: $breakpoint-sm) {
                flex-wrap: wrap;
                > * {
                    // flex: 1 1 100%;

                    text-align: center;

                    margin-left: 0.5rem;
                }
            }
        }
    }
    @media (max-width: $breakpoint-sm) {
        padding: 0;
    }
    > * i {
        margin-right: 0.5rem;
    }
}
