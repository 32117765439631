.NavbarTop {
    background-color: #fff;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    margin-bottom: 0;
    position: fixed;
    transition: all .2s ease;
    width: calc(100% - #{$offscreen-size});
    z-index: 800;
    padding: 0 15px;

    .SideBarToggle {
        cursor: pointer;
        i {
            font-size: 17px;
        }
    }

    .LogoLink {
        img {
            width: auto;
            height: 50px;
        }

        .SiteName {
            display: inline-block;
            color: $default-text-color;
            font-size: 1.625rem;
            margin-top: -6px;
        }
    }

    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-flex;

        > li {
            display: inline-flex;
            align-items: center;
            padding: 0 15px;
        }
    }

    .LogoLink {
        display: inline-flex;
        align-items: center;
    }
}
