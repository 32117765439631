.ArtworksListContainer {
    .ArtworkItem {
        .Created {
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $secondary;
            color: #fff;
            padding: 6px 10px;
        }

        .IconRemove {
            position: absolute;
            z-index: 2;
            right: 5px;
            top: 5px;
            background-color: $danger;
            color: #fff;
            font-size: 12px;
            padding: 5px 6px;
            cursor: pointer;
            line-height: 1;
            border-radius: 4px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
        }

        .DeleteLabel {
            position: absolute;
            z-index: 2;
            right: 5px;
            top: 5px;
            opacity: .75;
        }

        &:hover {
            .IconRemove {
                opacity: 1;
                visibility: visible;
            }
        }

        &.isDeleted {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(225, 225, 225, 0.7);
                z-index: 2;
            }
        }
    }
}
