.FacebookPixelContainer {
    .Title {
        margin-bottom: 20px;
    }

    .CollectionPixelIds {
        ul {
            padding-left: 40px;
        }

        li {
            list-style: initial;
            white-space: normal;
            word-break: break-word;
        }
    }
}
