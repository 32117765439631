.PageTitle {
  font-size: 1.75rem;
  font-weight: 500;
}

@media (max-width: $breakpoint-md) {
  .PageTitle {
    padding: 0 15px;
  }
}
