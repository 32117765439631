.OrganizationEdit {
    .EditSection {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        overflow: initial !important;

        .Title {
            font-weight: 500;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 20px;
        }

        .Block {
            border-top: 1px solid #dfe3e8;
            padding: 1rem 20px;
            margin: 0.5rem 0;
        }
    }
}
