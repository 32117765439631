.AccountDetails {
    display: flex;
    justify-content: space-between;
    border-width: 1px;
    margin-bottom: 1rem;
    background-color: $grey-200;

    .GroupName {
        margin: 1rem .75rem;
        flex: 0 0 30%;

    }
    .GroupDetail {
        margin: 1rem 0;
        flex: 1;
    }
        .Status {
            &.active {
                background-color: $default-primary;
            }
        }
}
