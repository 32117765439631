.PFFilterOptions {
    display: inline-block;

    select {
        width: auto;
        min-width: 145px;
    }

    .RadioLayout {
        margin-left: 0;
        padding-left: 0;
        font-size: .875rem;
        font-weight: 500;

        > .Option {
            border: solid 1px #dee2e6;
            display: inline-block;
            cursor: pointer;
            padding: 8px 14px;
            transition: all .3s ease;

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }

            &:not(:first-child) {
                margin-left: -1px;
            }

            &.Active {
                border-color: #0f9aee;
                color: #0f9aee;
                position: relative;
                z-index: 0;
            }
        }
    }
}
