.ListShippingRates,
.ListProductLine{
    padding: 0 0 0 20px;
    margin-top: 20px;
    li{
        list-style: outside disc;
        line-height: 1.6;
    }
}
.ListShippingRates{
    margin-top: 10px;
    margin-left: 30px;
}

.ListItemProductLine{
    tr.hide, tr.hide:hover{
        color: #CED4DA;
        background-color: transparent;
    }
}

.SettingShippingPage{
    .PagePagination{
        border: none;
    }
}

@media (max-width: 767px) {
    .ShippingProductLine,
    .ListItemProductLine {
        table {
            th td, tr td{
                min-width: 160px;
            }
            .ColAllSelection{
                min-width: auto;
                width: 50px;
            }
        }
    }
}


