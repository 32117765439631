.VariantSelect {
    display: flex;
    justify-content: center;
    align-items: center;

    .VariantBulkEdit {
        display: flex;
        justify-content: center;
        align-items: center;

        .PriceBulkInput {
            margin-right: .5rem;
            width: 10rem;
        }
    }
}
