.StoreImportProducts{
    .SelectInputStore{
        > div {
            width: 100%;
            > div{
                border: none;
                border-radius: 0;
                border-left: 1px solid rgba(0, 0, 0, 0.125);
            }
        }
    }
}
