.StoreTagsList {
    .ListTags {
        .StoreTagItem {
            margin-left: 0.2rem;

            &:first-child {
                margin-left: -2rem;
            }
        }
    }
}