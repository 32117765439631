.ProjectVersionsPage {
    .SearchBar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .SearchInput {
            border-radius: 21px;
            border-width: 1px;
            margin-right: -2.75rem;
            width: 40%;
        }
        .SearchIcon {
            padding: 0.75rem 1rem 0.45rem;
        }
    }
    .Filter {
        display: flex;
        > * {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}
