.TemplateForm {
    .label_upload {
        border: 1px solid #c9ccce;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .label_upload_error {
        border: 1px solid red;
        box-sizing: border-box;
        border-radius: 4px;
    }

    #uploadFile {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .FormDetail {
        .invalid-feedback {
            color: red;
        }

        .switch {
            display: inline-block;
            width: 40px;
            height: 20px;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + .slider {
                    background-color: #2196f3;
                }

                &:focus + .slider {
                    box-shadow: 0 0 1px #2196f3;
                }

                &:checked + .slider:before {
                    -webkit-transform: translateX(20px);
                    -ms-transform: translateX(20px);
                    transform: translateX(20px);
                }
            }

            .slider {
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 40px;
                background-color: #ccc;
                transition: 0.4s;

                &:before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    left: 2px;
                    bottom: 2px;
                    border-radius: 50%;
                    background-color: white;
                    transition: 0.4s;
                }
            }
        }

        .FormDetailItem {
            thead {
                background-color: #E9ECEF;
            }

            td, th {
                border: 1px solid #ddd;
                padding: 16px 40px;
                word-break: break-all;
                font-size: 14px;
            }
        }

        .error {
            border: 1px solid red;
        }
    }
}

.modal {
    &-container {
        display: flex !important;
        align-items: center !important;
    }
    &-amazon {
        width: 500px;
    }
}
