.CashbackRuleFormWrapper {
    .CashbackRuleFormContainer {
        .InputGroup {
            label {
                margin: 0;
            }
            .btn {
                min-width: 120px;
                height: 40px;
            }
            .DateRangePicker {
                width: 100%;
                .DateRangePickerInput {
                    border-radius: 3px;
                    line-height: 38px;
                    padding-left: 5px;
                }
                .DateInput_input {
                    font-weight: 400;
                    letter-spacing: 0;
                    color: #000;
                }
                input::placeholder {
                    color: #000;
                }
                .DateRangePickerInput_arrow,
                .DateInput {
                    background: none;
                    vertical-align: baseline;
                }
                .DateRangePickerInput_clearDates {
                    background: none;
                    &:focus {
                        outline: none;
                    }
                    svg {
                        fill: #000;
                        height: 11px;
                        vertical-align: baseline;
                    }
                    &:hover {
                        svg {
                            fill: #ff0000;
                        }
                    }
                }
            }
            .InputStore,
            .DateRangePicker .DateRangePickerInput {
                width: 100%;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #ced4da;
            }
            &.invalid-date {
                .DateRangePicker .DateRangePickerInput {
                    border: 1px solid #dd3d4c;
                }
            }
        }
    }
}
