.ProductGalleryContainer {
    .ProductGallerySortableContainer {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(6, 1fr);

        > div:empty {
            display: none;
        }
    }

    .ProductGalleryUploader {
        border: dashed 2px #dee2e6;
        border-radius: 4px;
        text-align: center;

        .Icon {
            font-size: 30px;
        }

        .PFBoxUploader {
            position: relative;
            width: 100%;
            padding-top: 100%;
        }

        .BoxUploader {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    //.ProductGalleryShowMore {
    //    .Content:hover .Icon {
    //        animation-name: shakeY;
    //        animation-fill-mode: both;
    //        animation-iteration-count: 1;
    //        animation-duration: 1s;
    //    }
    //}

    .ProductGalleryInvalidImages {
        background-color: rgba(220, 53, 69, .1);
        padding: 16px;
        margin-bottom: 16px;

        ul {
            padding-left: 30px;
            list-style: initial;
        }

        li {
            list-style: initial;
        }
    }
}
@include media-breakpoint-down(sm) {
    .ProductGalleryContainer .ProductGallerySortableContainer{
        grid-template-columns: auto;
    }
}