.ProductArtworks {
    .Title {
        font-weight: 500;
        margin-bottom: 20px;
    }

    .BackSide {
        .BottomBadge {
            background-color: $info;
        }
    }

    .FrontSide {
        .BottomBadge {
            background-color: $secondary;
        }
    }

    .ArtworkUploadWrapper {

        .HiddenUploader {
            display: none;
        }
    }

    .ArtworksWrapper {
        position: relative;
        min-height: 6rem;

        .NoArtworks {
            margin: 2rem 0;
            width: 100%;
            display: flex;
            justify-content: center;
            justify-items: center;
        }

        .LoadingWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            padding: 2rem 0;
            z-index: 10;
            background: rgba(255, 255, 255, .5);

            .Spinner {
                width: 4rem;
                height: 4rem;
            }
        }

        .Artwork {
            position: relative;
            padding-bottom: 100%;

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }


            .TopBadge {
                opacity: .75;
                position: absolute;
                z-index: 9;
                right: 5px;
                top: 5px;
            }

            .BottomBadge {
                position: absolute;
                z-index: 9;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 6px 10px;
                color: #fff;
            }

            .RemoveButton {
                padding: 5px 6px;
                cursor: pointer;
                opacity: 0;
                transition: all .1s linear;
            }

            &:hover {
                .RemoveButton {
                    opacity: 1;
                }
            }

            &.isDeleted {
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(225, 225, 225, .7);
                    z-index: 2;
                }
            }
        }
    }

    .LoadMore {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
