.BackWrapper {
    .BackButton {
        display: inline-block;
        cursor: pointer;
        padding-left: 0;
        padding-right: 0;

        span {
            margin: 0 1rem;
            position: relative;
        }

        //&:hover {
        //    > span::after {
        //        content: "";
        //        position: absolute;
        //        left: 0;
        //        right: 0;
        //        bottom: -2px;
        //        height: 1px;
        //        background-color: $primary;
        //    }
        //}
    }
}
