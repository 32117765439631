.UpsellAddProduct {
  button {
    background-color: #ffffff;
    border: none;
    text-decoration: underline;
    padding-left: 0;

    &:focus {
      outline: none;
    }
  }
}

.UpsellAddProductModal {
  .modal-header {
    button.close:focus {
      outline: none;
    }
  }
}
