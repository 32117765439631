.Catalogs {
    .CatalogsInner {
        background-color: hsl(0%, 0%, 100%);
        border-radius: 4px;
        border-width: 1px;
        .CategoriesSelector {
            width: 250px;
            .form-group {
                span {
                    margin-right: 10px;
                }
            }
        }
    }

    .TableFilter {
        width: auto;
        display: flex;
        align-items: center;
        margin: 0;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        .InputWrapper {
            width: auto;
            flex-grow: 1;

            .InputSearch {
                height: auto;
            }
        }
    }
}

.CatalogDetail {
    .CatalogDetailInner {
        .CatalogVariants {
            .nav > .nav-item {
                cursor: pointer;
            }
            .ShippingTable,
            .DesignTable,
            .BaseCostTable,
            .RelatedProductTable {
                max-height: 600px;
                overflow-y: auto;
            }
            .ShippingContainer {
                .ShippingTable {
                    .table thead th {
                        vertical-align: middle;
                    }
                    .table {
                        .label {
                            min-width: 400px;
                        }
                        .color,
                        .size {
                            min-width: 200px;
                        }
                        .Zone {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
        .CatalogAttributesList {
            ul {
                padding: 0;
            }
            .CatalogAttributeItem {
                .ListAttributes {
                    .Name {
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 16px;
                            background-color: #fff;
                            right: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}

.VariantDimension {
    .DimensionItem {
        padding: 1rem;
        border-radius: 4px;
        border: 1px solid #ced4da;
    }
}

.VariantBaseCostPricing {
    .pricingPrefix {
        position: relative;
        width: 150px;
        input {
            padding-left: 40px;
            padding-right: 12px;
            text-align: right;
        }
        .prefix {
            position: absolute;
            left: 0;
            background-color: rgb(243, 242, 241);
            top: 50%;
            padding: 0.375rem 0.75rem;
            transform: translate(13px, -18px);
            border-radius: 4px 0 0 4px;
        }
    }
    .table thead th {
        padding-right: 30px;
        position: relative;
        vertical-align: middle;
        min-width: 190px;
        border-bottom: none;
        border-top: none;
        img {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -10px);
        }
        &.action {
            padding-right: 0;
            min-width: 100px;
        }
    }
    .table thead td {
        border-bottom: none;
        border-top: none;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        &.error {
            border-color: #ff0000;
        }
    }
    table {
        border-bottom: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        border-collapse: separate; /* Don't collapse */
        border-spacing: 0;
        thead {
            position: sticky;
            top: 0;
            background-color: #e9ecef;
            z-index: 3;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #dee2e6;
                content: "";
                width: 100%;
                height: 100%;
            }
        }
        td {
            border-left: 1px solid #dee2e6;
        }
        th {
            border-left: 1px solid #dee2e6;
            .basic-single {
                font-weight: 400;
            }
        }

        .ProductVariantsRow {
            position: relative;
            z-index: 0;
            background-color: #ffffff;
        }
    }
    &.BaseCostShipping {
        table {
            td {
                vertical-align: top;
            }
        }
        .pricingPrefix .prefix {
            top: 0;
            transform: translate(13px, 13px);
        }
        .select__menu {
            width: max-content;
            left: 0;
            right: auto;
        }
    }
    &:not(.BaseCostShipping) {
        table {
            th {
                &:last-child {
                    position: sticky;
                    right: 0;
                    z-index: 3;
                }
            }
            td {
                &:last-child {
                    position: sticky;
                    right: 0;
                    z-index: 2;
                    background-color: #ffffff;
                }
            }
        }
    }

    .stickyBottom {
        //background-color: #fff;
        padding: 20px 0 0;
        //position: sticky;
        //border-top: 1px solid #f0f0f0;
        //bottom: 0;
    }
    .select__menu {
        width: max-content;
        right: 0;
        left: auto;
    }
}
