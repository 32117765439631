.AccountsPage {
    .AccountsPageInner {
        .SearchBar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 320px;
            margin: 0.5rem 0;
            .SearchInput {
                border-radius: 4px;
                border-width: 1px;
                margin-right: -2.75rem;
            }
            .SearchIcon {
                padding: 0.75rem 1rem 0.45rem;
            }
        }
        @media (max-width: 767px) {
            .SearchBar {
                width: 100%;
                min-width: 100%;
            }
        }
        td.Method {
            width: 165px;
        }
    }
}


.SelectExportAccounts{
    .custom-control-label{
        &:before,
        &:after{
            top: 0.50rem;
        }
    }
}
