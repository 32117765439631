@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.Editor {
  .rdw-option-wrapper, .rdw-dropdown-wrapper {
    box-sizing: initial;
    border-color: #ced4da;
    border-radius: 4px;
  }

  .EditorToolbar {
    background-color: #fafbfc;
    border-color: #ced4da;
    margin-bottom: 0;
    padding: 5px;
    border-radius: 4px 4px 0 0;
  }

  .EditorApp {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    min-height: 15rem;
    background-color: white;
  }

  .rdw-embedded-wrapper {
    margin-bottom: 0;
  }
}

.rdw-list-wrapper {
  margin: 5px 0;
}

.rdw-option-wrapper {
  padding: 0.5rem !important;
}

.rdw-image-wrapper {
  margin: 5px 0;
}

.rdw-text-align-wrapper {
  margin: 5px 0;
}

.rdw-block-wrapper {
  // height: 100%;
  margin: 5px 0;
}

.rdw-dropdown-wrapper {
  min-height: 35px;
  min-width: 50px;

  &:hover {
    box-shadow: none;
  }
}

.rdw-link-wrapper {
  min-height: 35px;
  margin: 5px 0;
}

.rdw-fontsize-wrapper {
  margin: 5px 0;
}

.rdw-fontfamily-wrapper {
  margin: 5px 0;
}

.rdw-inline-wrapper {
  margin: 5px 0;
}

.rdw-option-active {
  box-shadow: none;
  border-color: #80bdff;
}

.rdw-dropdown-optionwrapper {
  overflow-y: hidden;
}

.rdw-link-modal {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  top: 40px;
  border-radius: 0.25rem;
  height: auto;

  .rdw-link-modal-input {
    padding: 0.5rem;
    height: 39px;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border-color: #80bdff;
    }
  }

  .rdw-link-modal-buttonsection {
    .rdw-link-modal-btn {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
      border-radius: 0.25rem;

      &:hover {
        box-shadow: none;
      }
    }
  }
}

.rdw-image-modal {
  margin-top: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 0.75rem;

  .rdw-image-modal-header {
    margin: 0;

    .rdw-image-modal-header-option {
      .rdw-image-modal-header-label-highlighted {
        width: 100%;
        border-bottom: 1px solid #80bdff;
      }
    }
  }

  .rdw-image-modal-url-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border-color: #80bdff;
    }
  }

  .rdw-image-modal-size-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border-color: #80bdff;
    }
  }

  .rdw-image-mandatory-sign {
    display: none;
  }

  .rdw-image-modal-btn {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 0.25rem;

    &:hover {
      box-shadow: none;
    }
  }
}

@include media-breakpoint-down(xs) {
  .Editor .EditorToolbar{
    position: relative;
    .rdw-image-wrapper{
      position: static;
      .rdw-image-modal{
        top: 100%;
        margin: 0;
        width: 100%;
        left: 0;
        border-radius: 0;
      }
    }
  }
}


/* QL Editor*/

.quill .ql-snow .ql-editor {
  font-size: 1rem;
  font-weight: 400;
  color: #303238;
  line-height: 1.5;

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }
}
