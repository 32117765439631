.ProductApproval {
    .ProductApprovalTableList {
        table th:nth-child(2) {
            width: 350px;
        }
        table {
            .ItemTrademark {
                max-width: 350px;
                min-width: 250px;
                p {
                    line-height: 22px;
                }
            }
            .OrderList {
                width: 170px;
                min-width: 170px;
            }
        }
        .actions {
            a {
                cursor: pointer;
            }
        }
        .item-trademark {
            .item-trademark-image {
                width: 100px;
            }
            li:not(:last-child) {
                border-bottom: 1px solid #c3c3c3;
            }
        }
    }
}

.TableErrors {
    max-height: 70vh;
    overflow-y: auto;

    table {
        table-layout: fixed;
    }
}

.SelectedAttributesProduct {
    max-width: 280px;
    min-width: 220px;
}

.list-dashed {
    padding-left: 20px;
    list-style-type: none;
    & > li {
        &::before {
            content: "-";
            margin-right: 5px;
        }
    }
}
