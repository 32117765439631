.ProductSelectedDiscount {
  input {
    max-width: 120px;
  }

  .ProductPrice {
    .Origin {
      text-decoration: line-through;
    }
  }
}
