.ProductLines {
    .ProductLinesInner {
        background-color: hsl(0%, 0%, 100%);
        border-radius: 4px;
        border-width: 1px;
        .CategoriesSelector {
            max-width: 480px;
            .form-group {
                span {
                    margin-right: 10px;
                }
            }
        }
    }

    .ImageColumn {
        background-color: transparent;
        width: 100px;
    }

    .CurrencyColumn {
        width: 120px;
    }

    .IndexColumn {
        width: 100px;
    }

    .TableBody {
        align-content: center;
    }

    .addbtn {
        margin-bottom: 1.5rem;
    }

    .CategoriesSelector {
        .form-group {
            align-items: center;
        }
    }

    .TableFilter {
        width: auto;
        display: flex;
        align-items: center;
        margin: 0;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        .InputWrapper {
            width: auto;
            flex-grow: 1;

            .InputSearch {
                height: auto;
            }
        }
    }

    .selectProductLine {
        width: auto;
    }

    .TableContainer {
        overflow-y: hidden;

        .FulfillmentProductTypeColumn {
            .badge {
                font-weight: 500;
                border-radius: 10px;
                background: #dfe3e8;
            }
        }
    }
}

.CreateProductPageContainer,
.EditProductLine {
    .ProductAttributesList {
        ul {
            padding: 0;
        }
    }

    .CategoriesSelector {
        padding: 0;

        .form-group {
            flex-flow: column;
        }

        span {
            margin-bottom: 8px;
        }

        .selectProductLine {
            padding: 0;
        }
    }
}

.ProductLineDescription {
    .Editor {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;

        .EditorToolbar,
        .EditorApp {
            border: none;
        }

        .EditorToolbar {
            border-bottom: 1px solid #ced4da;
        }
    }

    &.Error {
        .Editor {
            border: 1px solid #dc3545;
        }
    }
}

.selectProductLine {
    &.error {
        > div {
            border: 1px solid #dc3545;
        }
    }
    > div {
        background: #fff;
        z-index: 11;
    }
}

.CategoriesSelector.Categories {
    position: relative;
    z-index: 15;
    .selectProductLine {
        > div {
            background: #fff;
            z-index: 12;
        }
    }
}
.ProductLineSizeChart {
    .tox-editor-container {
        .editorOverlay.active {
            background-color: rgba(255, 255, 255, 0.75);
            z-index: 9;
            position: absolute;
            width: 100%;
            height: 40px;
            top: 0;
        }
    }
}

.ProductLineSizeChartImage {
    .icon-upload-image {
        i {
            font-size: 30px;
            vertical-align: middle;
        }
    }
    .wrapperImagePreview {
        .img-preview {
            width: 200px;
        }
    }
}
.CategoriesSelectorCustom {
    min-width: 200px;
}
.TableProductHighlight {
    .ImageColumn {
        max-width: 100px;
    }
}

