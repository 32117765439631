.TagEdit {
    display: block;

    .Tags {
        display: inline-block;
        margin: 0;
        width: 100%;
        list-style-type: none;
        padding: 0;

        .Tag {
            display: block;
            float: left;
            margin-right: 0.5rem;
            margin-bottom: 5px;
            padding: 0.1rem 0.3rem;
            background-color: #dfe3e8;
            border-radius: 4px;
            color: #454f5b;

            .CloseButton {
                margin-left: 0.5rem;
                cursor: pointer;
                color: #798c9c;
            }
        }
    }
}
