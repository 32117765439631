.Box
 {
    flex: 1;
    &:not(:last-child) {
        margin-right: .5rem;
    }
    .card {
        height: 100%;
        .card-body {
            .card-item{
                display: flex;
                justify-content: space-between;   
            }
        }
    }
}