.MenuItem {
    position: relative;

    .SubMenu {
        padding-left: 0;
        max-height: 400px;
        overflow-y: hidden;
        .nav-link {
            font-weight: normal;
            white-space: initial;
            padding: 5px 0;
        }
        .Title {
            display: none;
        }
        > ul{
            padding-right: 20px;
        }
        .MenuItem {
            display: flex;
            align-items: center;
            padding: 6px 0;
            &:not(:last-child) {
                border-bottom: solid 1px rgba(222, 226, 230, .6);
            }
        }
    }

    .nav-link{
        .active-icon {
            display: none;
        }
        &.active {
            .default-icon {
                display: none;
            }
            .active-icon {
                display: inline-block;
            }
        }
    }
}
