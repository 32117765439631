.ProfilesTable{
    &__thead{
        tr{
            white-space: nowrap;
        }
    }
    &__tbody{
        tr{
            td:last-child{
                vertical-align: center;
                > div{
                    display: inline-flex;
                }
            }
        }
    }
}
