@import './CreateShippingProfiles';
@import './ListShippingProfiles';
@import './ZonePage';


.ZoneEditPageContainer{
    ul{
        padding-left: 0;
        margin-bottom: 0;
    }
}

.SettingTableLoading{
    td{
        width: 100%;
        height: 40px;
    }
}
